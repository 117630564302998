.burningLyricsView {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  background-color: rgb(1, 0, 1);
}

#burninglyrics-container {
  z-index: 1000;
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  #burninglyrics-container {
    margin-left: 2%;
  }
}

@media screen and (max-width: 768px) {
  #burninglyrics-container {
    margin-left: 2%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #burninglyrics-container {
    margin-left: 2%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  #burninglyrics-container {
    margin-left: 2%;
  }
}

#burninglyrics-text .active {
  display: flex;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

#burninglyrics-top-text {
  color: rgb(253, 0, 0);
  font: 15px Courier;
  padding-bottom: 50px;
  margin-top: 75px;
}


#burninglyrics-text {
  list-style-type: none;
  color: rgb(253, 0, 0);
  font: 15px Courier;
  padding-bottom: 3px;
}

#burninglyrics-text :hover {
  opacity: 0.5;
  cursor: pointer;
  margin-top: -5px;
  margin-left: 5px;
}