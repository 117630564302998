/* Fonts */

@font-face {
  font-family: 'Myfont-Regular';
  src: local('Myfont-Regular'), url(../resources/fonts/Myfont-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Myfont2-Regular';
  src: local('Myfont2-Regular'), url(../resources/fonts/Myfont2-Regular.ttf) format('truetype');
}

/* Page View */

#header {
  font-family: 'Playfair Display', serif;
  font-size: 35px;
  width: 5px;
  color: white;
  cursor: pointer;
}

.albumView {
  background-color:rgb(1, 0, 1);
  padding:2rem;
}

.albumView > nav {
	text-align: center;
  margin-bottom: 25px;
  margin-left: 35px;
}

.albumView > nav > a {
	text-align: center;
	color:#FFF;
  font-size: 35px;
  color: white;
  cursor: pointer;
  font-family: 'Playfair Display', serif;
	margin-top: -15px;
}

/* Text */

.top-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5vh;
}

.top-text {
  font-family: 'Myfont-Regular', serif;
  font-size: 2rem;
  font-style: italic;
  color: white;
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  .top-text {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 768px) {    
  .top-text {
    font-size: 2.25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .top-text {
    font-size: 3.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .top-text {
    font-size: 3.5rem;
  }
}

.burning-container {
  margin-top: 8.5vh;
  margin-bottom: 10vh;
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  .burning-container {
    margin-bottom: 10vh;
  }
}

@media screen and (max-width: 768px) {    
  .burning-container {
    margin-bottom: 10vh;
  }
}

.burning-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#burning-text  {
  list-style-type: none;
  color: 	rgb(253, 0, 0);
  font: 15px Courier;
  margin-bottom: 25px;
}

#burning-text:hover {
  opacity: 0.5;
  cursor: pointer;
  margin-top: -5px;
  margin-left: -5px;
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  #burning-text {
    font-size: .95rem;
  }
}

@media screen and (max-width: 768px) {    
  #burning-text {
    font-size: .90rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #burning-text {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  #burning-text {
    font-size: .95rem;
  }
}

.album-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.album-text {
  font-family: 'Myfont-Regular', serif;
  font-size: 4rem;
  color: white;
}

@media screen and (max-width: 768px) {    
  .album-text {
    font-size: 3rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .album-text {
    margin-top: -1rem;
    width: 5.5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .album-text {
    width: 5.5rem;
  }
}

.under-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.under-text {
  font-family: 'Myfont-Regular', serif;
  font-size: 2rem;
  font-style: italic;
  color: white;
}

@media screen and (max-width: 768px) {    
  .under-text {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .under-text {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .under-text {
    font-size: 1.75rem;
  }
}

.player-over-text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.player-over-text {
  font-family: 'Myfont-Regular', serif;
  font-size: 1.5rem;
  color: white;
  margin-bottom: -1rem;
}

.player-text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
}

.player-text {
  font-family: 'Myfont-Regular', serif;
  font-size: 1.5rem;
  color: white;
  margin-top: .5rem;
  margin-bottom: 2rem;
}

#album-text  {
  list-style-type: none;
  color: 	rgb(253, 0, 0);
  font: 15px Courier;
}

#album-text :hover {
  opacity: 0.5;
  cursor: pointer;
  margin-top: -5px;
  margin-left: 5px;
}



/* AR Model */

#video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  padding-bottom: 2rem;
}

#video-container model-viewer {
  display: flex;
  height: 80vh;
  width: 80vh;
  top: 0;
  left: 0;
  border: none;
}

.viewer-text {
  background: #ffffff;
  border-radius: 0px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(255, 0, 0, 0.8);
  font-family: 'Myfont-Regular', serif;
  font-size: 15px;
  font-weight: 1000;
  max-width: 200px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
}

@media screen and (max-width: 768px) {    
  .viewer-text {
    display: block;
    font-size: 10px;
    font-weight: 1000;
    max-width: 150px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    width: max-content;
    height: max-content;
    transform: translate3d(-50%, -50%, 0);
  }
}

#view-button {
  background: #ffffff;
  border-radius: 0px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: 'Myfont-Regular', serif;
  font-size: 15px;
  font-weight: 1000;
  max-width: 200px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
}

@media screen and (max-width: 768px) {    
  #view-button {
    display: block;
    font-size: 10px;
    font-weight: 1000;
    max-width: 150px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    width: max-content;
    height: max-content;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #view-button {
    display: block;
    font-size: 15px;
    font-weight: 1000;
    max-width: 200px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    width: max-content;
    height: max-content;
    transform: translate3d(-50%, -50%, 0);
  }
}  

@media screen and (max-width: 768px) {    
  #video-container model-viewer {   
    height: 45vh;
    width: 45vh;
  }
}

@media screen and (max-width: 768px) {    
  #video-container {
    display: flex;
    flex-direction: column;
    margin-top: -5rem;
    margin-left: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #video-container model-viewer {
    height: 60vh;
    width: 60vh;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #video-container {
    display: flex;
    flex-direction: column;
    margin-top: -5rem;
    margin-left: 5rem;
    padding-bottom: 1.5rem;
  }
} 



/* Nail Bomb Art */

#nailbombart {
  display: block;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  width: 25rem;
}

@media screen and (max-width: 768px) {    
  #nailbombart {
    display: block;
    width: 15rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #nailbombart {
    margin-top: -1rem;
    width: 15rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  #nailbombart {
    width: 15rem;
  }
}



/* DSP Tiles */

#dsprow2 {
  padding-top: 1rem;
  padding-bottom: 5rem;
}

#dsprow {
  padding-bottom: 2rem;
}


#applewar {
  width: 5.5rem;
  opacity: 0.5;
}

#applewar:hover {
  opacity: 1;
  cursor: pointer;
}

#spotifywar {
  width: 5.25rem;
  opacity: 0.5;
}

#spotifywar:hover {
  opacity: 1;
  cursor: pointer;
}

#scwar {
  width: 5.25rem;
  opacity: 0.5;
}

#scwar:hover {
  opacity: 1;
  cursor: pointer;
}

#yt {
  width: 5.25rem;
  opacity: 0.5;
}

#yt:hover {
  opacity: 1;
  cursor: pointer;
}

#tidalwar {
  width: 5.25rem;
  opacity: 0.5;
}

#tidalwar:hover {
  opacity: 1;
  cursor: pointer;
}

#bcwar {
  width: 5.25rem;
  opacity: 0.5;
}

#bcwar:hover {
  opacity: 1;
  cursor: pointer;
}

#otherwar {
  opacity: 0.6;
}

#otherwar:hover {
  opacity: 1;
  cursor: pointer;
}


/* Video Player */

.react-player-wrapper {
  width: auto; 
  height: auto; 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.react-player {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 768px) {    
  .youtube-player-1 {
    position: absolute;
  }
}

/* .youtube-player-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 25vh;
  padding-top: 1rem;
} */



/* War! Row */

#mask {
  display: block;
  cursor: pointer;
  width: 15rem;
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
   animation: flickerAnimation 1s infinite;
}

@media screen and (max-width: 768px) {
  #mask {
    width: 10rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #mask {
    width: 10rem;

  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  #mask {
    width: 10rem;
  }
}


/* #tank {
  display: block;
  width: 15rem;
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
   animation: flickerAnimation 2s infinite;
}

#helmet {
  display: block;
  width: 15rem;
  -webkit-animation: flickerAnimation 1.5s infinite;
  -moz-animation: flickerAnimation 1.5s infinite;
  -o-animation: flickerAnimation 1.5s infinite;
   animation: flickerAnimation 1.5s infinite;
}

#flower {
  display: block;
  width: 15rem;
  -webkit-animation: flickerAnimation .75s infinite;
  -moz-animation: flickerAnimation .75s infinite;
  -o-animation: flickerAnimation .75s infinite;
   animation: flickerAnimation .75s infinite;
}

#ak {
  display: block;
  width: 15rem;
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
   animation: flickerAnimation 1s infinite;
} */



/* Grid */

.albumView > .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-template-rows: repeat(2, 275px);
  grid-gap: 1rem;
  grid-auto-flow: dense;
  margin-left: -2rem;
  padding-top: 2rem;
}

.albumView > .grid > li {
  opacity: .65;
}

.albumView > .grid > li:hover {
  opacity: 1;
  margin-top: -5px;
  margin-left: -5px;
}

.albumView > .grid > li:nth-child(1n) {
  grid-column: span 2;
  grid-row: span 2;
}

.albumView > .grid > li:nth-child(2n) {
  grid-column: span 1;
  grid-row: span 1;
}

.albumView > .grid > li:nth-child(3n) {
  grid-column: span 1;
  grid-row: span 2;
}

.albumView > .grid > li:nth-child(4n) {
  grid-column: span 1;
  grid-row: span 2;
}

.albumView > .grid > li:nth-child(5n) {
  grid-column: span 1;
  grid-row: span 1;
}

.albumView > .grid > li > figure {
  height: 100%;
}
.albumView > .grid > li > figure > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .albumView > .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    grid-template-rows: repeat(2, 275px);
    grid-gap: 1rem;
    grid-auto-flow: dense;
    padding-bottom: 5rem;
  }
  
  .albumView > .grid > li:nth-child(1n) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .albumView > .grid > li:nth-child(2n) {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(3n) {
    grid-column: span 1;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(4n) {
    grid-column: span 1;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(5n) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .albumView > .grid > li > figure {
    height: 100%;
  }
  .albumView > .grid > li > figure > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .albumView > .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    grid-template-rows: repeat(2, 275px);
    grid-gap: 1rem;
    grid-auto-flow: dense;
  }
  
  .albumView > .grid > li:nth-child(1n) {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(2n) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .albumView > .grid > li:nth-child(3n) {
    grid-column: span 1;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(4n) {
    grid-column: span 1;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(5n) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .albumView > .grid > li > figure {
    height: 100%;
  }
  .albumView > .grid > li > figure > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .albumView > .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    grid-template-rows: repeat(2, 275px);
    grid-gap: 1rem;
    grid-auto-flow: dense;
  }
  
  .albumView > .grid > li:nth-child(1n) {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(2n) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .albumView > .grid > li:nth-child(3n) {
    grid-column: span 1;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(4n) {
    grid-column: span 1;
    grid-row: span 2;
  }
  
  .albumView > .grid > li:nth-child(5n) {
    grid-column: span 1;
    grid-row: span 1;
  }
  
  .albumView > .grid > li > figure {
    height: 100%;
  }
  .albumView > .grid > li > figure > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}



/* Flicker Animation */

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:.55; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:.55; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:.55; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:.55; }
  100% { opacity:1; }
}




