.trackLyricsView {
    width: 100vw;
    min-height: 150vh;
    background-color:rgb(1, 0, 1);
  }  

  #lyrics-text  {
    list-style-type: none;
    color: 	rgb(253, 0, 0);
    font: 15px Courier;
  }